<template lang="pug">
.col-6
    .advantage
        img.q-mb-sm(:src="`${img}`")
        div
            p.q-mb-sm.font-16.text-weight-medium.text-primary
                | {{ title }}
            span.text-primary
                | {{ description }}
</template>

<script>

export default {
    name: 'Advantages',
    props: {
        id: {
            type: Number,
            required: false,
        },
        img: {
            type: String,
        },
        title: {
            type: String,
            required: true,
        },
        description : {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.advantage {
    display: flex;
    div {
        margin-left: 16px;
    }
    span {
        font-size: 16px;
        padding-right: 48px;
    }
    @media screen and (max-width: $breakpoint-mobile) {
        display: block;
        div {
            margin-left: 0;
        }
        span {
            font-size: 14px;
            padding-right: 0;
        }
    }
}
</style>

