<template lang="pug">
.full-width(ref="wrapper")
    q-skeleton(
        width="200px"
        height="22px"
        :class="[`bg-${background}`, titleClasses]"
        v-if="title").q-mt-sm.q-mb-md
    .row(v-if="styleSkeleton")
        .skeleton.q-pa-lg(
            v-for="i in count"
            :key="i"
            :style="styleSkeleton")
            .skeleton__img
                q-skeleton(
                    type="rect"
                    width="100%"
                    height="100%"
                    :class="`bg-${background}`")
            q-skeleton(
                    width="60%"
                    height="22px"
                    :class="`bg-${background}`").q-mt-md
            q-skeleton(
                width="100%"
                height="18px"
                :class="`bg-${background}`").q-mt-sm
            q-skeleton(
                width="35%"
                height="20px"
                :class="`bg-${background}`").q-mt-sm
            q-skeleton(
                width="100%"
                height="38px"
                :class="`bg-${background}`").skeleton__button.q-mt-md
</template>

<script>
import { ref, computed, onMounted } from 'vue';

export default {
    name: 'ProductSliderSkeletonDesktop',
    props: {
        height: {
            type: Number,
            default: 290,
        },
        background: {
            type: String,
            default: 'grey-6',
        },
        title: {
            type: Boolean,
            default: false,
        },
        titleClasses: {
            type: String,
            default: '',
        },
        countSlides: {
            type: Number,
        },
    },
    setup(props) {
        const wrapper = ref(null);
        const styleSkeleton = ref(null);

        const count = computed(() => {
            return props.countSlides
                ? props.countSlides
                : 5;
        });

        const marginLeft = computed(() => 16);

        onMounted(() => {
            styleSkeleton.value = `
            height: ${props.height}px;
            width: ${(wrapper.value.clientWidth - (marginLeft.value * (count.value - 1))) / count.value}px;
            margin-left: ${marginLeft.value - 1}px`;
        });

        return { styleSkeleton, count, wrapper };
    },
};
</script>

<style lang="scss" scoped>
.skeleton {
  border: $app-border;
  border-radius: 10px;

  &__img {
    height: 40%;
  }

  &__button {
    border-radius: 10px !important;
  }

  &:first-child {
    margin-left: 0 !important;
  }

  &-mobile {
    height: 240px;
    width: calc(50% - 8px);

    &:first-of-type {
        margin-right: 8px;
    }

    &:last-of-type {
        margin-left: 8px;
    }
  }
}

.q-skeleton {
  border-radius: 4px;

  @media screen and (max-width: $breakpoint-mobile) {
      border-radius: 10px;
  }
}
</style>
