import { cityIn } from 'lvovich';
import { cityFrom } from 'lvovich';

export function getValid_In(city) {
    const blackList = {
        'Маслова Пристань': 'Масловой Пристани',
        'Белые Берега': 'Белых Берегах',
    };
    return city === null ? cityIn('Белгород') : blackList[city] ? blackList[city] : cityIn(city);
}
export function getValid_From(city) {
    const blackList = {
        'Маслова Пристань':  'Масловой Пристани',
        'Белые Берега': 'Белых Берегов',
        'Бирюч': 'Бирюча',
        'Валуйки': 'Валуек',
        'Ивня': 'Ивни',
    };
    return city === null ? cityFrom('Белгород') : blackList[city] ? blackList[city] : cityFrom(city);
}
