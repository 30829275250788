<template lang="pug">
.row
    q-skeleton(
        type="rect"
        width="100%"
        height="270px"
        :class="`bg-${background}`")
    .row.full-width.justify-center.q-mt-md
        q-skeleton(
            type="circle"
            width="12px"
            height="12px"
            :class="`bg-${background}`").q-mx-sm
        q-skeleton(
            type="circle"
            width="12px"
            height="12px"
            :class="`bg-${background}`").q-mx-sm
        q-skeleton(
            type="circle"
            width="12px"
            height="12px"
            :class="`bg-${background}`").q-mx-sm
</template>

<script>
export default {
    name: 'CarouselSkeleton',
    props: {
        background: {
            type: String,
            default: 'grey-6',
        },
    },
};
</script>
